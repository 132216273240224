import { ReactNode } from 'react';
import { toast, Zoom, ToastOptions, ToastItem } from 'react-toastify';
import { LOToastOptions } from './types';

type ToastId = ToastOptions['toastId'];

const toastsQueue = new Set<ToastId>();
const registerOpenToast = (id?: ToastId) => id && toastsQueue.add(id);
const unregisterOpenToast = (id?: ToastId) => id && toastsQueue.delete(id);
export const isToastInQueue = (id?: ToastId) => !!id && toastsQueue.has(id);

toast.onChange((payload: ToastItem) => {
    switch (payload.status) {
        case 'removed':
            unregisterOpenToast(payload.id);
            break;
    }
});

const showOrderNotificationToast = (content: ReactNode, options: LOToastOptions): void => {
    const toastOptions: ToastOptions = {
        onClose: options.onClose,
        toastId: options.toastId,
        autoClose: options.autoClose ?? 10000,
        position: toast.POSITION.TOP_RIGHT,
        transition: Zoom,
        hideProgressBar: true,
        draggable: false,
        closeOnClick: options.onCtaButtonClick === undefined && options.autoClose !== false,
        closeButton: false,
        style: { padding: 0, marginBottom: 16, background: 'none' },
        containerId: 'orderNotificationsContainer'
    };

    registerOpenToast(options.toastId);
    toast(content, toastOptions);
};

export default showOrderNotificationToast;
