import { orderStatus } from './enums/orderStatusesEnum';

export const APP_STORE_LINK = 'https://apps.apple.com/app/live-orders/id1562296823';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.justeattakeaway.liveorders';

export const COURIER_THEN_RESTAURANT = 'courier_then_restaurant';
export const SCOOBER_UNIFIED_FLOW = 'scoober_unified_flow';
export const GROCERY_UNIFIED_FLOW = 'grocery_unified_flow';
export const UNIFIED_POS_FLOW = 'scoober_unified_flow_pos';
export const THIRD_PARTY_GROCERY_FLOW = 'third_party_logistics_grocery_flow';
export const RESTAURANT_OPEN = 'open';
export const RESTAURANT_DELIVERY_OPEN = 'open-delivery';
export const RESTAURANT_PICKUP_OPEN = 'open-pickup';
export const RESTAURANT_CLOSE = 'close';
export const RESTAURANT_DELIVERY_CLOSE = 'close-delivery';
export const RESTAURANT_PICKUP_CLOSE = 'close-pickup';
export const OWN_DELIVERY = 'own_delivery';

export const SCOOBER = 'scoober';
export const STUART = 'stuart';
export const NOTIME = 'notime';
export const YUSO = 'yuso';
export const DOORHUB = 'doorhub';
export const DELCO = 'delco';
export const GASTROKURIER = 'gastrokurier';
export const FLEETLERY = 'fleetlery';

export const ASAP = 'asap';
export const DELIVERY = 'delivery';
export const PICKUP = 'pickup';
export const CASH = 'cash';
export const MEAL_VOUCHER = 'meal_voucher';
export const ONLINE = 'online';

export const GET_FROM_STORAGE = 'get_from_storage';
export const ADD_TO_STORAGE = 'add_to_storage';
export const REMOVE_FROM_STORAGE = 'remove_from_storage';
export const LOCAL_STORAGE = 'localStorage';
export const SESSION_STORAGE = 'sessionStorage';

export const AVAILABLE_LANGUAGES = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    nl: 'Nederlands',
    it: 'Italian',
    pl: 'Polski',
    pt: 'Portugues',
    bg: 'Bulgarian',
    ro: 'Romanian',
    da: 'Danish',
    no: 'Norwegian',
    sk: 'Slovak'
};

export const AVAILABLE_TENANTS = {
    uk: 'https://live-orders.just-eat.uk',
    ie: 'https://live-orders.just-eat.ie',
    it: 'https://live-orders.just-eat.it',
    es: 'https://live-orders.just-eat.es',
    au: 'https://live-orders.just-eat.au',
    nz: 'https://live-orders.just-eat.nz'
};

export const ORDER_STATUSES_SEQUENCE = [
    orderStatus.NEW,
    orderStatus.CONFIRMED,
    orderStatus.KITCHEN,
    orderStatus.IN_DELIVERY,
    orderStatus.DELIVERED,
    orderStatus.CANCELLED
];

export const NOTIFICATION_PERMISSION_GRANTED = 'granted';
export const NOTIFICATION_PERMISSION_DENIED = 'denied';

export const MIN_DEFAULT_TIME = 5;
export const MAX_DEFAULT_TIME = 50;

export const ORDER_IS_NEW_AND_ASAP = 'orderIsNewAndAsap';
export const ORDER_IS_NEW_AND_PREORDER = 'orderIsNewAndPreorder';

export const OFL_FEATURE = 'ofl_enabled';
