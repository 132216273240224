import React, { useEffect } from 'react';
import { useOrderNotificationsActions, useOrderNotifications } from '@lo/shared/store/orderNotifications';
import { getNotificationLevel, getNotificationMessage } from '@lo/shared/helpers/notification/orderNotification';
import { useOrdersStoreActions } from '@lo/shared/store/orders';
import { OrderNotification } from '@lo/shared/types/notificationType';
import showOrderNotificationToast, { isToastInQueue } from '@lo/shared/services/toaster/showOrderNotificationToast';
import Notification from '../../../sharable-components/Notification/Notification';
import useOrders from '@lo/shared/hooks/useOrders';
import useRestaurant from '@lo/shared/hooks/useRestaurant';

const useOrderNotificationsToasts = () => {
    const restaurant = useRestaurant();
    const notifications = useOrderNotifications();
    const actions = useOrderNotificationsActions();
    const { openOrderDetails } = useOrdersStoreActions();
    const { data } = useOrders();

    const dispatchToast = (notification: OrderNotification) => {
        const title = notification.order
            ? `#${notification.order.public_reference.toUpperCase()}`
            : getNotificationLevel(notification.type);

        const autoClose = notification.type === 'PrepareOrder' ? false : undefined;
        showOrderNotificationToast(
            <Notification
                compact
                onClick={() => {
                    actions.readNotification(notification.id);
                    notification.order?.id && openOrderDetails(notification.order.id);
                }}
                notification={{
                    title,
                    message: getNotificationMessage(notification.type),
                    level: getNotificationLevel(notification.type),
                    type: notification.type,
                    id: notification.id
                }}
            />,
            { toastId: notification.id, autoClose }
        );
    };

    useEffect(() => {
        if (data?.some((order) => order.with_alcohol)) {
            actions.pushOrderNotification(restaurant.reference, 'AlcoholInfo');
        }
    }, [data]);

    useEffect(() => {
        notifications.filter((item) => !item.read && !isToastInQueue(item.id)).forEach(dispatchToast);
    }, [notifications]);
};

export default useOrderNotificationsToasts;
