import { create } from 'zustand';
import { OrderNotification, OrderNotificationType } from '../types/notificationType';
import transformEntityTimeToDateObjects from '../helpers/transformEntityTimeToDateObjects';
import compareAsc from 'date-fns/compareAsc';
import { hideToast } from '../services/toaster';
import { OrderModel } from '../models';
import { buildOrderNotification } from '../helpers/notification/orderNotification';

type OrderNotificationsStoreType = {
    notifications: OrderNotification[];
    actions: {
        pushOrderNotification: (restaurantReference: string, type: OrderNotificationType, order?: OrderModel) => void;
        readNotification: (notificationId: OrderNotification['id']) => void;
    };
};

export const useOrderNotificationsStore = create<OrderNotificationsStoreType>()((set) => ({
    notifications: [] as OrderNotification[],
    actions: {
        pushOrderNotification: (restaurantReference, type, order) => {
            const notification = buildOrderNotification(restaurantReference, type, order);

            set(({ notifications }) => {
                if (notifications.find((item) => item.id === notification.id)) {
                    return { notifications };
                }

                return { notifications: [...notifications, notification] };
            });
        },
        readNotification: (notificationId) =>
            set(({ notifications }) => {
                const newData = [...notifications];
                const notificationIndex = newData.findIndex((item) => item.id === notificationId);
                if (notificationIndex >= 0) {
                    hideToast(notificationId);
                    newData[notificationIndex].read = true;
                }
                return {
                    notifications: newData
                };
            })
    }
}));

/** Hooks */
export const useOrderNotifications = () =>
    useOrderNotificationsStore((state) => transformEntityTimeToDateObjects(state.notifications));

export const useLastOrderNotification = (orderId: number, restaurantReference: string) => {
    const { notifications } = useOrderNotificationsStore();
    return transformEntityTimeToDateObjects(notifications)
        .filter((item) => item.order && item.order.id === orderId && item.restaurantReference === restaurantReference)
        .sort((itemA, itemB) => compareAsc(itemA.createdAt, itemB.createdAt))[0];
};

export const useOrderNotificationsActions = () => useOrderNotificationsStore((state) => state.actions);
