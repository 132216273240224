import Echo from 'laravel-echo';
import io, { Socket } from 'socket.io-client';
import { getAccessToken } from '@lo/shared/services/auth';
import getListenersMap from './getListenersMap';
import queryClient from '../query/queryClient';
import { hideToast, showErrorToast } from '../toaster';
import i18n from '../../localization/i18n';
import config from '../../helpers/config';
import { reloadPage } from '../../helpers/reloadPage';
import getExtraHeaders from './getExtraHeaders';
import { RestaurantModel } from '../../models';
import { useAppStatusStore } from '../../store/appStatus';

let instance: Echo | null = null;

let isReconnecting = false;
let connectionFailures = 0;

const handleConnectionError = () => {
    showErrorToast(i18n.t('orders.live_orders_messages.main.please_try_later'), {
        autoClose: false,
        ctaButtonText: i18n.t('orders.live_orders_messages.main.reload_page'),
        onCtaButtonClick: reloadPage,
        toastId: 'connection-error'
    });
};

const listenToRestaurantEvents = (restaurant: RestaurantModel) => {
    /*
     * Leave all channels to avoid possible duplication of listeners.
     */
    instance?.leaveAllChannels();

    const listenersMap = getListenersMap(restaurant);

    for (const [channel, event, callback] of listenersMap) {
        instance?.listen(channel, event, callback);
    }
};

const listenToConnectionEvents = (restaurant: RestaurantModel) => {
    const socket = instance?.connector.socket as Socket;

    socket.on('connect', () => {
        if (isReconnecting) {
            /**
             * Socket.io successfully reconnected.
             * Need to refresh all data that could be updated during the downtime.
             */
            queryClient.invalidateQueries();
            isReconnecting = false;
        }

        listenToRestaurantEvents(restaurant);
        hideToast('connection-error');
        connectionFailures = 0;
    });

    socket.on('connect_error', () => {
        isReconnecting = true;
        connectionFailures++;

        if (connectionFailures === 5) {
            handleConnectionError();
        } else {
            instance?.connector.socket.connect();
        }
    });

    socket.on('subscription_error', () => {
        if (isReconnecting) {
            handleConnectionError();
        } else {
            isReconnecting = true;
            connect(restaurant);
        }
    });

    socket.on('disconnect', (reason) => {
        const { isActive, hasInternetConnection } = useAppStatusStore.getState();

        /*
         * We don't want to reconnect in such cases:
         * 1) the app is in the background (reconnection most likely won't be successful. We will reconnect when the app is active again.)
         * 2) there's no internet connection
         * 3) the disconnection was caused by the client (logout, etc.)
         */
        if (isActive && hasInternetConnection && reason !== 'io client disconnect') {
            isReconnecting = true;

            const randomDelay = Math.random() * (10000 - 3000) + 3000;
            setTimeout(() => instance?.connector.socket.connect(), randomDelay);
        }
    });
};

export const connect = async (restaurant: RestaurantModel) => {
    instance?.disconnect();

    const token = await getAccessToken();

    instance = new Echo({
        broadcaster: 'socket.io',
        client: (typeof window !== 'undefined' && window.io) || io,
        host: config.socketHost,
        namespace: '',
        reconnection: false, // We handle reconnection manually
        auth: {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-Restaurant-Id': restaurant.reference
            }
        },
        transports: ['websocket'],
        extraHeaders: await getExtraHeaders()
    });

    listenToConnectionEvents(restaurant);
};

export const isConnected = () => instance && instance.connector.socket && instance.connector.socket.connected;

export const disconnect = () => instance?.disconnect();

useAppStatusStore.subscribe((state) => {
    const shouldReconnect = state.isActive && state.hasInternetConnection && !isConnected();

    if (shouldReconnect) {
        getAccessToken().then((token) => {
            if (!instance || !token) return;

            instance.options.auth.headers.Authorization = `Bearer ${token}`;
            instance.connector.socket.connect();
        });
    }
});
