import addOrUpdateItemInArray from '../../../helpers/array/addOrUpdateItemInArray';
import { shouldIgnoreOrderUpdate } from '../../../helpers/order/shouldIgnoreOrderUpdate';
import { showOrderUpdateNotification } from '../../../helpers/order/showOrderUpdateNotification';
import { playSound } from '../../../helpers/playSound';
import queryClient from '../../query/queryClient';
import i18n from '../../../localization/i18n';
import { showSuccessToast } from '../../toaster';
import { OrderModel, RestaurantModel } from '../../../models';
import { OrderData } from '../../../types/orderDataType';
import { RestaurantData } from '../../../types/restaurantDataType';
import { useOrdersStore } from '../../../store/orders';
import { useOrderNotificationsStore } from '../../../store/orderNotifications';

export default (newOrderData: OrderData) => {
    const ordersState = useOrdersStore.getState();
    const notificationsState = useOrderNotificationsStore.getState();

    const orderWasManuallyUpdated = ordersState.manuallyChangedOrderIds.includes(newOrderData.id);

    const restaurantData = queryClient.getQueryData<RestaurantData>(['restaurant']);
    const restaurant = restaurantData && new RestaurantModel(restaurantData);

    const newOrder = new OrderModel(newOrderData);
    const orders = queryClient.getQueryData<OrderModel[]>(['orders', restaurant?.id]) ?? [];
    const orderDataToUpdate = orders.find((o) => o.id === newOrderData.id);
    const orderToUpdate = orderDataToUpdate && new OrderModel(orderDataToUpdate);

    if (orderToUpdate === undefined || restaurant === undefined || shouldIgnoreOrderUpdate(orderToUpdate, newOrder, restaurant)) {
        return;
    }

    queryClient.setQueryData<OrderData[]>(
        ['orders', restaurant.id],
        addOrUpdateItemInArray<OrderData>(newOrderData, [...orders])
    );
    queryClient.invalidateQueries({ queryKey: ['orders', 'details', newOrderData.id] });

    const needToShowNotification = showOrderUpdateNotification(newOrder, orderToUpdate);

    // Do not play sound if order was updated manually previously
    if (!orderWasManuallyUpdated && needToShowNotification && restaurant) {
        playSound(restaurant.ui_settings.order_update_sound || 'default');
    }

    ordersState.actions.receivedOrderUpdateFromSockets(newOrder.id);

    if (newOrder.is_cancelled) {
        ordersState.actions.orderCancelled(newOrder.public_reference);

        return;
    }

    const courierAdded = newOrder.is_in_kitchen && newOrder.couriers.length > orderToUpdate.couriers.length;
    const orderIsReadyForKitchen = newOrder.is_ready_for_kitchen && !orderToUpdate.is_ready_for_kitchen;

    if (courierAdded || orderIsReadyForKitchen) {
        notificationsState.actions.pushOrderNotification(restaurant.reference, 'PrepareOrder', newOrder);

        return;
    }

    if (needToShowNotification) {
        showSuccessToast(
            i18n.t('orders.live_orders_messages.main.orderid_has_been_updated', { orderId: newOrder.public_reference }),
            { toastId: `order-${newOrder.id}` } // Providing custom toast id to avoid duplicates
        );
    }
};
